import SelectApiClient from '@/api/SelectApiClient'
import SelectApiClientType from '@/api/SelectApiClientType'

export default {
    data() {
        return {
            preventUserDeleteDialog: {
                open: false,
                orders: [],
            },
        }
    },
    methods: {
        async checkUserOrders(userId) {
            const orders = await SelectApiClient.getSelectApiResponse(
                SelectApiClientType.ORDERS_PREVENT_USER_DELETE,
                { userId }
            )
            if (orders.length) {
                this.preventUserDeleteDialog.isOpen = true
                this.preventUserDeleteDialog.orders = orders
                return false
            }

            return true
        },
    },
}
