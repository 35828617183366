<template>
    <modal-dialog
        :value="modelValue.isOpen"
        :max-width="500"
        :title="$t('users.preventDeleteDialog.title')"
        scrollable
        :fullscreen="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
        @input="$emit('input', $event)"
    >
        <p>
            {{
                $t('users.preventDeleteDialog.message', {
                    count: modelValue.orders.length,
                })
            }}
        </p>
        <p>
            {{ $t('users.preventDeleteDialog.orders') }}
            <br />
            <v-chip
                v-for="order in orderList"
                :key="order.id"
                label
                :to="`/orders/${order.id}`"
                small
                class="mr-1 mb-1"
                >{{ order.name }}</v-chip
            >
            <span v-if="modelValue.orders.length > 50">...</span>
        </p>
        <template v-slot:footer>
            <v-btn :to="url">{{
                $t('users.preventDeleteDialog.showAll')
            }}</v-btn>
        </template>
        <template v-slot:actions>
            <ok-button @ok="close"></ok-button>
        </template>
    </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/dialog/ModalDialog.vue'
import OkButton from '@/components/dialog/OkButton.vue'

export default {
    components: {
        OkButton,
        ModalDialog,
    },
    props: {
        value: {
            type: Object,
            default: () => ({
                isOpen: false,
                orders: [],
            }),
        },
    },
    data() {
        return {
            orders: [],
        }
    },
    computed: {
        orderList() {
            return this.modelValue.orders.slice(0, 50)
        },
        modelValue: {
            set(value) {
                this.$emit('input', value)
            },
            get() {
                return this.value
            },
        },
        url() {
            const query = new URLSearchParams()
            query.set('order[createdAt]', 'desc')
            query.set('page', '1')
            query.set('itemsPerPage', '50')
            for (let order of this.modelValue.orders) {
                query.append('id', order.id)
            }

            return `/orders/?${query}`
        },
    },
    methods: {
        close() {
            this.modelValue.isOpen = false
            this.modelValue.orders = []
        },
    },
}
</script>
