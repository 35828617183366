<template>
    <div>
        <user-route-tabs
            :user-id="entityId"
            :tab-errors="tabErrors"
            :is-user-balance-enabled="!isUserBalanceDisabled"
        ></user-route-tabs>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            :disable-save-button="activeTab > 0"
            :disable-delete-button="activeTab > 0 || form.id === userId"
            :pre-delete-hook="() => checkUserOrders(entityId)"
            @created="created($event)"
            @updated="updated($event)"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <template v-if="!isSuperAdmin || !form['@id']">
                <general-form-part v-model="form"></general-form-part>
            </template>
            <template v-else>
                <v-tabs-items v-model="activeTab">
                    <!-- GENERAL TAB -->
                    <v-tab-item :transition="false" :reverse-transition="false">
                        <general-form-part
                            v-model="form"
                            @tabErrorsContainer="
                                mergeTabErrorsContainer($event)
                            "
                        ></general-form-part>
                    </v-tab-item>
                </v-tabs-items>
            </template>
        </base-form>
        <prevent-user-delete-dialog
            v-model="preventUserDeleteDialog"
        ></prevent-user-delete-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import routeType from '@/router/routeType'
import authType from '@/store/type/authType'
import ApiClientType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormExtend from '@/components/mixins/FormExtend'
import AuthMixin from '@/components/mixins/AuthMixin'
import UserRouteTabs from './RouteTabs'
import GeneralFormPart from './GeneralFormPart'
import RestApiCollection from '@/api/RestApiCollection'
import PreventUserDelete from '@/components/pages/users/mixins/PreventUserDelete'
import PreventUserDeleteDialog from '@/components/dialog/PreventUserDeleteDialog.vue'

export default {
    components: {
        PreventUserDeleteDialog,
        GeneralFormPart,
        UserRouteTabs,
    },
    mixins: [FormExtend, AuthMixin, PreventUserDelete],
    data() {
        return {
            api: ApiClientType.USERS,
            mapper: MapperType.USERS_FORM,
            form: {
                firstName: '',
                lastName: '',
                email: '',
                plainPassword: '',
                phone: '',
                acceptedTermsVersion: 1,
                acceptedPrivacyVersion: 1,
                enabled: true,
                company: null,
                adminOfCompany: null,
                adminOfRestaurant: [],
                extraRoles: [],
                isSuperAdmin: false,
                canCreateUsers: false,
                allowances: [],
                companyAddresses: [],
                notificationSettings: {},
                companyTeams: [],
            },
            editRoute: routeType.USER_EDIT,
            listRoute: routeType.USER_LIST,
        }
    },
    computed: {
        ...mapGetters({
            userId: authType.getters.USER_ID,
        }),
        title: function () {
            return (
                (this.form.firstName ? this.form.firstName : '') +
                ' ' +
                (this.form.lastName ? this.form.lastName : '')
            )
        },
        isUserBalanceDisabled() {
            return (
                (this.form.allowances.length === 0 &&
                    this.form.companyTeams.length === 0) ||
                !this.isSuperAdmin
            )
        },
    },
    watch: {
        activeCompany: {
            immediate: true,
            handler(activeCompany) {
                if (!this.entityId) {
                    this.form.company = activeCompany
                }
            },
        },
        'form.company': {
            immediate: true,
            handler(company) {
                if (!company) {
                    this.$set(this.form, 'companyAddresses', [])
                } else {
                    this.loadCompanyAddresses()
                }
            },
        },
    },
    methods: {
        editRedirectParams: (data) => ({ userId: data.id }),
        async loadCompanyAddresses() {
            this.busy = true

            if (this.form.company) {
                const data = await RestApiCollection.get(
                    ApiClientType.COMPANY_ADDRESSES
                ).list({
                    page: 1,
                    itemsPerPage: 99,
                    'company[exact]': this.form.company,
                })

                if (data['hydra:totalItems'] === 1) {
                    let companyaddress = []
                    companyaddress.push(data['hydra:member'][0]['@id'])
                    this.$set(this.form, 'companyAddresses', companyaddress)
                }
            }

            this.busy = false
        },
    },
}
</script>
